import { searchStringsToResultMode } from './Config';
import { ResultsMode } from './ConfigTypes';

export const WEB_DESIGNER_TOMORROW_MORE_PCTS = [85, 84, 81, 80, 79];

export const getResultsMode = (searchString: string): ResultsMode => {
     const s = searchString.toLowerCase();
     for (let searchStringToCheck of Object.keys(searchStringsToResultMode)) {
          const allStringsPresent = searchStringToCheck
               .split(',')
               .every((stringToCheck) => s.includes(stringToCheck));
          if (allStringsPresent) {
               return searchStringsToResultMode[searchStringToCheck];
          }
     }
     return searchStringsToResultMode[''];
};

export const badgeForMode = (mode: ResultsMode) => {
     return mode === ResultsMode.CTO_FINTECH
          ? 'Experience in Fintech'
          : mode === ResultsMode.CTO
            ? 'Experience in Healthtech'
            : null;
};
