import { ExpansionsAndRefinements, ResultsMode, ResultsPcts } from './ConfigTypes';

const { WEB_DESIGNER, WEB_DESIGNER_TOMORROW, COO, CHEAP_CFO, CTO_FINTECH, CTO, PYTHON_DEVELOPER } = ResultsMode;
const { WillingToConsiderRemoteCandidates, SearchOutsideSuggestedRate, SearchRemoteWithinTheUS, SearchRemoteGlobally, IncludeFewerYearsOfExperience: IncludeFewerYearsOfExperience, FlexibleOnStartDate: FlexibleOnStartDate, AddCooWithExperienceInFintech: AddCooWithExperienceInFintech, HasHealthcareExperience } = ExpansionsAndRefinements;

export const Pcts: Record<ResultsMode, ResultsPcts> = {
     [WEB_DESIGNER]: [98, 98, 94, 89, 82],
     [WEB_DESIGNER_TOMORROW]: [79, 71, 70, 65, 60],
     [COO]: [98, 98, 94, 89, 82],
     [CHEAP_CFO]: [98, 98, 94, 89, 82],
     [CTO_FINTECH]: [98, 98, 94, 89, 82],
     [CTO]: [98, 98, 94, 89, 82],
     [PYTHON_DEVELOPER]: [98, 98, 96, 94, 85],
};


export const ResultsModeToExpansionsOrRefinements: Record<ResultsMode, ExpansionsAndRefinements[]> = {
     [WEB_DESIGNER]: [],
     [WEB_DESIGNER_TOMORROW]: [FlexibleOnStartDate, WillingToConsiderRemoteCandidates],
     [COO]: [AddCooWithExperienceInFintech],
     [CHEAP_CFO]: [SearchOutsideSuggestedRate, SearchRemoteWithinTheUS, SearchRemoteGlobally],
     [CTO_FINTECH]: [IncludeFewerYearsOfExperience],
     [CTO]: [HasHealthcareExperience],
     [PYTHON_DEVELOPER]: []
}

export const ExpansionAndRefinementConfig: Record<ExpansionsAndRefinements, { label: string, type: 'EXPANSION' | 'REFINEMENT' }> = {
     // expansions
     [SearchOutsideSuggestedRate]: { label: 'Include results to include candidates at $200 per hour?', type: 'EXPANSION' },
     [SearchRemoteWithinTheUS]: { label: 'Include remote workers within the US?', type: 'EXPANSION' },
     [SearchRemoteGlobally]: { label: 'Include remote workers globally?', type: 'EXPANSION' },
     [IncludeFewerYearsOfExperience]:
          { label: 'Include less-experienced candidates?', type: 'EXPANSION' },
     [FlexibleOnStartDate]: { label: 'Flexible on start date?', type: 'EXPANSION' },
     [WillingToConsiderRemoteCandidates]:
          { label: 'Willing to consider remote candidates?', type: 'EXPANSION' },
     // refinements
     [AddCooWithExperienceInFintech]: { label: 'Refine to COOs with experience in Fintech', type: 'REFINEMENT' },
     [HasHealthcareExperience]:
          { label: 'Refine to candidates with experience in Healthtech', type: 'REFINEMENT' },

};


export const searchStringsToResultMode: Record<string, ResultsMode> = {
     'coo': COO,
     'cfo': CHEAP_CFO,
     'cto,fintech': CTO_FINTECH,
     'cto': CTO,
     'python': PYTHON_DEVELOPER,
     'web,tomorrow': WEB_DESIGNER_TOMORROW,
     '': WEB_DESIGNER,
}

export const ResultsModeToNumResults: Record<ResultsMode, number> = {
     [WEB_DESIGNER]: 5,
     [WEB_DESIGNER_TOMORROW]: 2,
     [COO]: 5,
     [CHEAP_CFO]: 0,
     [CTO_FINTECH]: 3,
     [CTO]: 5,
     [PYTHON_DEVELOPER]: 5,
}

export const ExpansionRefinementToNumResults: Record<ExpansionsAndRefinements, number> = {
     [ExpansionsAndRefinements.SearchOutsideSuggestedRate]: 5,
     [ExpansionsAndRefinements.SearchRemoteWithinTheUS]: 5,
     [ExpansionsAndRefinements.SearchRemoteGlobally]: 5,
     [ExpansionsAndRefinements.IncludeFewerYearsOfExperience]: 5,
     [ExpansionsAndRefinements.FlexibleOnStartDate]: 5,
     [ExpansionsAndRefinements.WillingToConsiderRemoteCandidates]: 5,
     [ExpansionsAndRefinements.AddCooWithExperienceInFintech]: 2,
     [ExpansionsAndRefinements.HasHealthcareExperience]: 3
}

type ResultOverride = ([number, boolean, boolean, boolean, boolean] | null)[];

export const ResultsModeToFlagsArray: Partial<Record<ResultsMode, ResultOverride>> = {
     [CTO]: [
          [98, true, true, true, true],
          [98, true, true, true, true],
          [94, true, true, true, true],
          [89, true, true, true, true],
          [82, true, true, true, false],
     ],
     [PYTHON_DEVELOPER]: [
          [98, true, true, true, true],
          [98, true, true, true, true],
          [94, true, true, true, true],
          [89, true, true, true, true],
          [82, true, true, true, false],
     ]
};

export const ExpansionRefinementToFlagsArray: Record<ExpansionsAndRefinements, ResultOverride> = {
     [ExpansionsAndRefinements.SearchOutsideSuggestedRate]: [
          [72, true, false, true, true],
          [71, true, false, true, true],
          [71, true, false, true, true],
          [70, true, false, true, true],
          [63, true, false, false, true],
     ],
     [ExpansionsAndRefinements.SearchRemoteWithinTheUS]: [
          [83, false, true, true, true],
          [83, false, true, true, true],
          [82, false, true, true, true],
          [81, false, true, true, true],
          [77, false, true, false, true],

     ],
     [ExpansionsAndRefinements.SearchRemoteGlobally]: [
          [83, false, true, true, true],
          [80, false, true, true, true],
          [80, false, true, true, true],
          [78, false, true, true, true],
          [77, false, true, false, true],
     ],
     [ExpansionsAndRefinements.IncludeFewerYearsOfExperience]: [
          null, null, null,
          [78, false, true, true, false],
          [77, false, true, false, false],

     ],
     [ExpansionsAndRefinements.FlexibleOnStartDate]: [
          [79, true, false, true, true],
          [71, true, false, false, true],
          [70, true, true, false, true],
          [70, true, false, false, true],
          [65, true, false, false, true],
     ],
     [ExpansionsAndRefinements.WillingToConsiderRemoteCandidates]: [
          [79, true, false, true, true],
          [71, true, false, false, true],
          [71, false, true, true, true],
          [71, false, true, true, true],
          [70, false, true, false, true],
     ],
     [ExpansionsAndRefinements.AddCooWithExperienceInFintech]: [],
     [ExpansionsAndRefinements.HasHealthcareExperience]: []
}