import { faker } from '@faker-js/faker';
import {
     Industry,
     LeadershipPosition,
     USState,
     EngagementType,
     EngagementLengthUnit,
     LocationType,
     Timezones,
     Language,
} from '../Enums';
import { FractionalProfileType } from '../onboarding/FractionalProfileType';

const generateFakeProfile = (): FractionalProfileType => {
     return {
          companyName: faker.company.name(),
          operatorOrCeoName: faker.name.fullName(),
          email: faker.internet.email(),
          phone: faker.phone.number(),
          city: faker.address.city(),
          state: faker.helpers.arrayElement(Object.values(USState)),
          zip: faker.address.zipCode(),
          profilePhoto: faker.image.avatar(),
          linkedIn: faker.internet.url(),
          website: faker.internet.url(),
          resume: faker.random.words(),
          category: faker.helpers.arrayElement(Object.values(LeadershipPosition)),
          typeOfService: faker.random.word(),
          industrySpecialization: faker.helpers.arrayElement(Object.values(Industry)),
          clientName: faker.company.name(),
          clientIndustry: Industry[faker.helpers.arrayElement(Object.values(Industry))],
          rating: faker.datatype.number({ min: 1, max: 5, precision: 0.1 }),
          // @ts-ignore
          engagementType: EngagementType[faker.helpers.arrayElement(Object.values(EngagementType))],
          engagementLengthMin: faker.datatype.number({ min: 1, max: 12 }),
          engagementLengthMax: faker.datatype.number({ min: 13, max: 24 }),
          engagementLengthUnit:
               EngagementLengthUnit[
                    // @ts-ignore
                    faker.helpers.arrayElement(Object.values(EngagementLengthUnit))
               ],
          engagementHoursPerMin: faker.datatype.number({ min: 10, max: 20 }),
          engagementHoursPerMax: faker.datatype.number({ min: 21, max: 40 }),
          engagementHoursPerUnit:
               EngagementLengthUnit[
                    // @ts-ignore
                    faker.helpers.arrayElement(Object.values(EngagementLengthUnit))
               ],
          engagementFeeMin: faker.datatype.number({ min: 5000, max: 10000, precision: 1000 }),
          engagementFeeMax: faker.datatype.number({ min: 11000, max: 20000, precision: 1000 }),
          enagementFeeUnit:
               EngagementLengthUnit[
                    // @ts-ignore
                    faker.helpers.arrayElement(Object.values(EngagementLengthUnit))
               ],
          // @ts-ignore
          location: LocationType[faker.helpers.arrayElement(Object.values(LocationType))],
          timezone: faker.helpers.arrayElement(Object.values(Timezones)),
          pctAvailToTravel: faker.datatype.number({ min: 0, max: 100, precision: 1 }),
          availability: faker.date.future().toLocaleDateString(),
          languagesSpoken: faker.helpers.arrayElement(Object.values(Language)),
     };
};

export const generateFakeFractionalProfiles = (count: number): FractionalProfileType[] => {
     const profiles: FractionalProfileType[] = [];
     for (let i = 0; i < count; i++) {
          profiles.push(generateFakeProfile());
     }
     return profiles;
};
