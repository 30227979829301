export enum ResultsMode {
    WEB_DESIGNER,
    WEB_DESIGNER_TOMORROW,
    COO,
    CHEAP_CFO,
    CTO_FINTECH,
    CTO,
    PYTHON_DEVELOPER,
}

export enum ExpansionsAndRefinements {
    // expansions:
    SearchOutsideSuggestedRate,
    SearchRemoteWithinTheUS,
    SearchRemoteGlobally,
    IncludeFewerYearsOfExperience,
    FlexibleOnStartDate,
    WillingToConsiderRemoteCandidates,
    // refinements:
    AddCooWithExperienceInFintech,
    HasHealthcareExperience
}
export type ResultsPcts = [number, number, number, number, number];
