import './_start/assets/sass/style.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';

import Container from 'react-bootstrap/Container';
import {
     Nav,
     Navbar,
     Form,
     NavDropdown,
     Accordion,
     Badge,
     Button,
     InputGroup,
     ListGroup,
     Spinner,
     Card,
} from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import advisorii from './BrightField-4.png';
import { SomeResults } from './App2/SomeResults';
import { Loading } from './App2/Loading';
import { Results } from './App2/Results';
import { ShowFor } from './App2/ShowFor';
import circle from './circle.png';
import { Chat1 } from './chat/Chat1';
import { ResultsMode } from './App2/ConfigTypes';

export type Mode = 'start' | 'search1' | 'search2' | 'search3' | 'loading' | 'results';

export const App2 = () => {
     const [mode, setMode] = useState<Mode>('start');
     const [chatVisible, setChatVisible] = useState(true);
     useEffect(() => {
          if (mode === 'search1') {
               setTimeout(() => setMode('search2'), 1500);
          }
     }, [mode]);
     return (
          <>
               {chatVisible && (
                    <Chat1
                         close={() => setChatVisible(false)}
                         initialText={[
                              { msg: 'The position sounds interesting to me.', you: false },
                              { msg: 'Are you available immediately?', you: true },
                              {
                                   msg: 'I will be available in two weeks, does that work for you?',
                                   you: false,
                              },
                              // {
                              //      msg: "Yes, let's find a time to chat to hammer out the final details.",
                              //      you: true,
                              // },
                              // {
                              //      msg: "Great, I just updated when I'll be free tomorrow",
                              //      you: false,
                              // },
                         ]}
                    />
               )}
               <Navbar
                    // sticky="top"
                    // bg="primary"
                    // data-bs-theme="dark"
                    expand="lg"
                    className="bg-body-tertiary"
               >
                    <Container fluid>
                         <Navbar.Brand href="#home" onClick={() => setMode('start')}>
                              <img src={advisorii} style={{ height: 30 }} />
                         </Navbar.Brand>
                         <Navbar.Toggle aria-controls="basic-navbar-nav" />
                         <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                              <Nav
                                   className="me-auto"
                                   ref={(node: any) =>
                                        node?.style.setProperty('margin-right', '0', 'important')
                                   }
                                   style={{ marginLeft: 'auto', marginRight: '0px !important' }}
                              >
                                   {/* <Nav.Link href="#home">
                                        <i className="bi bi-bell-fill"></i>
                                   </Nav.Link> */}
                                   <Nav.Link href="#link">
                                        <i className="bi bi-envelope-exclamation"></i>
                                        <div
                                             style={{
                                                  display: 'inline-block',
                                                  fontSize: '10px',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'var(--bs-primary)',
                                                  width: 16,
                                                  height: 16,
                                                  color: 'white',
                                                  textAlign: 'center',
                                                  fontWeight: 'bold',
                                                  position: 'relative',
                                                  left: -10,
                                                  top: 6,
                                             }}
                                        >
                                             9
                                        </div>
                                   </Nav.Link>
                                   <NavDropdown
                                        title={
                                             <img
                                                  src={circle}
                                                  style={{
                                                       height: 24,
                                                       width: 24,
                                                       display: 'inline',
                                                       top: -2,
                                                  }}
                                             />
                                        }
                                        // title={<i className="bi bi-person-circle"></i>}
                                        id="basic-nav-dropdown"
                                        align="end"
                                   >
                                        <NavDropdown.Item href="#action/3.1">
                                             Edit Profile
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href="#action/3.2">
                                             Account Settings
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href="#action/3.3">
                                             Administration
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.4">
                                             Log out
                                        </NavDropdown.Item>
                                   </NavDropdown>
                              </Nav>
                         </Navbar.Collapse>
                    </Container>
               </Navbar>
               <br></br>
               <br></br>
               <Homepage
                    mode={mode}
                    onEnter={() => {
                         setMode('loading');
                    }}
                    setMode={setMode}
               />
          </>
     );
};

export const Homepage = ({
     onEnter,
     mode,
     setMode,
}: {
     onEnter: () => void;
     mode: Mode;
     setMode: React.Dispatch<React.SetStateAction<Mode>>;
}) => {
     const dirtySearchString = useRef<string>('');
     const [searchString, setSearchString] = useState('');

     const submit = () => {
          console.log(`set searchString to ${dirtySearchString.current}`);
          setSearchString(dirtySearchString.current);
          onEnter();
     };
     return (
          <div style={{ padding: 10 }}>
               <Card>
                    {' '}
                    <Card.Body>
                         <ShowFor showFor="start" current={mode}>
                              <h1>Start New Search</h1>
                         </ShowFor>
                         <ShowFor
                              showFor={['search1', 'search2', 'search3', 'loading', 'results']}
                              current={mode}
                         >
                              <a href="#" onClick={() => setMode('start')}>
                                   <i className="bi bi-arrow-left"></i> Back
                              </a>
                         </ShowFor>
                         <Form
                              onSubmit={(e) => {
                                   e.stopPropagation();
                                   e.preventDefault();
                                   submit();
                              }}
                         >
                              <ShowFor showFor="start" current={mode}>
                                   <Form.Label>
                                        Type in what you are looking for in a candidate:
                                   </Form.Label>
                              </ShowFor>
                              <InputGroup>
                                   <Form.Control
                                        size="lg"
                                        autoFocus
                                        type="text"
                                        defaultValue="web designer, new york, $250/hour"
                                        placeholder='e.g. "Head of Data Science in Chicago"'
                                        // plaintext={mode !== 'start'}
                                        // readOnly={mode !== 'start'}
                                        onChange={(event) => {
                                             console.log(
                                                  `set dirtySearchString to ${event.target.value}`,
                                             );
                                             dirtySearchString.current = event.target.value;
                                        }}
                                   ></Form.Control>
                                   {/* <ShowFor showFor="start" current={mode}> */}
                                   <Button
                                        variant="primary"
                                        onClick={() => {
                                             submit();
                                        }}
                                        size="lg"
                                   >
                                        Search
                                   </Button>
                                   {/* </ShowFor> */}
                              </InputGroup>
                              <br></br>
                              <br></br>
                              <ShowFor showFor="search1" current={mode}>
                                   <Search1 />
                              </ShowFor>
                         </Form>

                         {mode === 'loading' && <Loading doneLoading={() => setMode('search2')} />}
                         <ShowFor showFor={['search2', 'search3', 'results']} current={mode}>
                              <Results searchString={searchString} />
                         </ShowFor>
                    </Card.Body>
               </Card>
               <ShowFor showFor="start" current={mode}>
                    <Card>
                         <Card.Body>
                              <div>
                                   <h1>
                                        You have <b>3</b> candidate searches in progress...
                                   </h1>
                                   <Accordion>
                                        <Accordion.Item eventKey="0">
                                             <Accordion.Header>
                                                  <h3>
                                                       <Badge
                                                            className="bg-light-primary"
                                                            style={{ color: 'black' }}
                                                       >
                                                            COO
                                                       </Badge>
                                                  </h3>
                                                  &nbsp; 5 new recommended candidates
                                             </Accordion.Header>
                                             <Accordion.Body>
                                                  <SomeResults num={5} mode={ResultsMode.COO} />
                                             </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                             <Accordion.Header>
                                                  <h3>
                                                       <Badge
                                                            className="bg-light-primary"
                                                            style={{ color: 'black' }}
                                                       >
                                                            Python Developer
                                                       </Badge>
                                                  </h3>
                                                  &nbsp;9 new recommended candidates
                                             </Accordion.Header>
                                             <Accordion.Body>
                                                  <SomeResults
                                                       num={7}
                                                       mode={ResultsMode.PYTHON_DEVELOPER}
                                                  />
                                             </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                             <Accordion.Header>
                                                  <h3>
                                                       <Badge
                                                            className="bg-light-warning"
                                                            style={{ color: 'black' }}
                                                       >
                                                            Quantitative Analyst
                                                       </Badge>
                                                  </h3>{' '}
                                                  &nbsp; Suggested Actions
                                             </Accordion.Header>
                                             <Accordion.Body>
                                                  <b>No new results</b>
                                                  <ListGroup>
                                                       <ListGroup.Item>
                                                            <a href="#">Specify a salary range?</a>
                                                       </ListGroup.Item>
                                                       <ListGroup.Item>
                                                            <a href="#">
                                                                 Look through remote and partially
                                                                 remote candidates?
                                                            </a>
                                                       </ListGroup.Item>
                                                  </ListGroup>
                                             </Accordion.Body>
                                        </Accordion.Item>
                                   </Accordion>
                              </div>
                         </Card.Body>
                    </Card>
               </ShowFor>
          </div>
     );
};

export const Search1 = () => (
     <h1>
          Loading recommended candidates based on your query... &nbsp;
          <Spinner animation="border" />
     </h1>
);
