import { ReactNode, useCallback, useEffect } from 'react';

export const FullScreenModal: React.FC<{
     close: () => any;
     title: string;
     backgroundColor?: string;
     children: ReactNode;
     width?: string;
     height?: string;
     closeButtonLabel?: string;
}> = ({
     close,
     title,
     children,
     backgroundColor = 'black',
     width = 'calc(100vw - 50px)',
     height = 'calc(100vh - 50px)',
     closeButtonLabel = 'Close',
}) => {
     const handleEsc = useCallback((event: KeyboardEvent) => {
          if (event.key === 'Escape') {
               close();
          }
     }, []);
     useEffect(() => {
          window.addEventListener('keydown', handleEsc);
          return () => {
               window.removeEventListener('keydown', handleEsc);
          };
     });

     return (
          <>
               <div
                    className="fade"
                    style={{
                         position: 'fixed',
                         width: '100vw',
                         height: '100vh',
                         backgroundColor: 'black',
                         opacity: 0.7,
                         zIndex: 2000,
                         left: 0,
                         top: 0,
                    }}
               ></div>

               <div
                    className="modal fade show"
                    id="exampleModalFullscreen"
                    tabIndex={-1}
                    aria-labelledby="exampleModalFullscreenLabel"
                    style={{
                         display: 'block',
                         width,
                         height,
                         backgroundColor,
                         border: '1px solid gray',
                         position: 'fixed',
                         top: '50%',
                         left: '50%',
                         transform: 'translate(-50%, -50%)',
                         borderRadius: 20,
                         zIndex: 2001,
                    }}
                    aria-modal="true"
                    role="dialog"
               >
                    <div className="modal-dialog modal-fullscreen" style={{ width: '100%' }}>
                         <div className="modal-content">
                              <div className="modal-header">
                                   <h1
                                        className="modal-title fs-4"
                                        id="exampleModalFullscreenLabel"
                                   >
                                        {title}
                                   </h1>
                                   <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={close}
                                   ></button>
                              </div>

                              {children}
                              <div className="modal-footer">
                                   <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={close}
                                   >
                                        {closeButtonLabel}
                                   </button>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
};
