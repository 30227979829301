import { Button, ButtonGroup, Form, InputGroup } from 'react-bootstrap';
import { SomeResults } from './SomeResults';
import { useCallback, useEffect, useState } from 'react';
import { ExpandAndRefineResults } from './Expand';
import { getResultsMode } from './ResultsModes';
import { ExpansionsAndRefinements, ResultsMode } from './ConfigTypes';
import {
     ExpansionRefinementToNumResults,
     ResultsModeToExpansionsOrRefinements,
     ResultsModeToNumResults,
} from './Config';

export const Results = ({ searchString }: { searchString: string }) => {
     const [expansionsAndRefinements, setExpansionsAndRefinements] = useState<
          ExpansionsAndRefinements[]
     >([]);
     const addOrRemoveExpansionRefinement = (which: ExpansionsAndRefinements, val: boolean) => {
          if (val) {
               setExpansionsAndRefinements([...expansionsAndRefinements, which]);
          } else {
               setExpansionsAndRefinements(expansionsAndRefinements.filter((x) => x !== which));
          }
     };

     const resultsMode = getResultsMode(searchString);
     console.log(`Searching for ${searchString} in ${ResultsMode[resultsMode]}`);

     useEffect(() => {
          const validExpansionsAndRefinements = expansionsAndRefinements.filter((x) =>
               ResultsModeToExpansionsOrRefinements[resultsMode].includes(x),
          );
          if (validExpansionsAndRefinements.join() !== expansionsAndRefinements.join()) {
               setExpansionsAndRefinements(validExpansionsAndRefinements);
          }
     }, [expansionsAndRefinements, resultsMode]);

     const numResults = expansionsAndRefinements.length
          ? ExpansionRefinementToNumResults[expansionsAndRefinements[0]]
          : ResultsModeToNumResults[resultsMode];

     return (
          <>
               <h1>
                    Top Recommended Candidates{' '}
                    <Button variant="success" style={{ float: 'right' }}>
                         Save this Search
                    </Button>
               </h1>
               <br style={{ clear: 'both' }} />
               {numResults === 0 && (
                    <>
                         <h3 style={{ marginTop: 20 }}>No candidates were recommended.</h3>
                    </>
               )}
               <SomeResults
                    expansionsAndRefinements={expansionsAndRefinements}
                    mode={resultsMode}
                    num={numResults}
               />
               <ExpandAndRefineResults
                    resultsMode={resultsMode}
                    currentExpansionAndRefinements={expansionsAndRefinements}
                    setExpansionRefinement={addOrRemoveExpansionRefinement}
               />
          </>
     );
};
