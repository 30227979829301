import { FullScreenModal } from '../FullScreenModal';
import video from '../video.png';
export const VideoWindow = ({ close, name }: { close: () => any; name: string }) => (
     <FullScreenModal {...{ close, title: `Video chat with ${name}` }}>
          <div
               className="modal-body"
               style={{
                    backgroundColor: 'black',
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 200,
               }}
          >
               <img src={video} style={{ maxHeight: '100%' }} />
          </div>
     </FullScreenModal>
);
