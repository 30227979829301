import { faker } from '@faker-js/faker';
import { Badge, Button, ButtonGroup, Dropdown, Stack } from 'react-bootstrap';
import { Heading, Link } from 'react-aria-components';
import { FractionalProfileType } from '../onboarding/FractionalProfileType';
import zoom from '../zoom.png';
import gmeet from '../gmeet.svg';
import { Chat1 } from '../chat/Chat1';
import { useState } from 'react';
import { VideoWindow } from '../chat/Video';
import { FullProfile } from './FullProfile';
import { Appointment } from '../chat/Appointment';
import { AudioWindow } from '../chat/Audio';
import { WEB_DESIGNER_TOMORROW_MORE_PCTS, badgeForMode } from './ResultsModes';
import { Variant } from 'react-bootstrap/esm/types';
import { ExpansionRefinementToFlagsArray, Pcts, ResultsModeToFlagsArray } from './Config';
import { ExpansionsAndRefinements, ResultsMode } from './ConfigTypes';

export const ProfileCard = ({
     profile,
     idx,
     mode,
     expansionsAndRefinements,
}: {
     profile: FractionalProfileType;
     idx: number;
     mode: ResultsMode;
     expansionsAndRefinements?: ExpansionsAndRefinements[];
}) => {
     const [isFullscreen, setIsFullscreen] = useState(false);
     const [chat, setChat] = useState(false);
     const { operatorOrCeoName, city } = profile;

     let pctMatch = Pcts[mode][idx] || 70;
     if (
          mode === ResultsMode.WEB_DESIGNER_TOMORROW &&
          (expansionsAndRefinements?.includes(ExpansionsAndRefinements.FlexibleOnStartDate) ||
               expansionsAndRefinements?.includes(
                    ExpansionsAndRefinements.SearchRemoteWithinTheUS,
               ) ||
               expansionsAndRefinements?.includes(ExpansionsAndRefinements.SearchRemoteGlobally))
     ) {
          pctMatch = WEB_DESIGNER_TOMORROW_MORE_PCTS[idx] | 78;
     }
     const overrideFlags = expansionsAndRefinements?.length
          ? ExpansionRefinementToFlagsArray[expansionsAndRefinements[0]]
          : null;
     if (overrideFlags !== null && overrideFlags.length > 0 && overrideFlags[idx]?.length) {
          const thisFlagset = overrideFlags[idx];
          if (thisFlagset !== null) {
               pctMatch = thisFlagset[0];
          }
     }

     const dontShowNewYork =
          mode === ResultsMode.WEB_DESIGNER_TOMORROW &&
          !!(
               expansionsAndRefinements?.includes(
                    ExpansionsAndRefinements.SearchRemoteWithinTheUS,
               ) ||
               expansionsAndRefinements?.includes(ExpansionsAndRefinements.SearchRemoteGlobally)
          );
     return (
          <>
               {chat && <Chat1 title={operatorOrCeoName} close={() => setChat(false)} />}
               {isFullscreen && (
                    <FullProfile profile={profile} close={() => setIsFullscreen(false)} />
               )}
               <div style={{ margin: 10 }}>
                    <span style={{ float: 'right' }}>
                         <ProfileDropdown openChat={() => setChat(true)} name={operatorOrCeoName} />
                    </span>
                    <span style={{ float: 'left', paddingRight: 10, margin: 10 }}>
                         <img
                              src={profile.profilePhoto}
                              style={{ borderRadius: '100%', height: 64 }}
                         />
                    </span>

                    <Heading>
                         {idx + 1}) &nbsp;
                         <Link href="#" onPress={() => setIsFullscreen(true)}>
                              {operatorOrCeoName}{' '}
                              <Badge
                                   style={{
                                        backgroundColor: 'white',
                                        padding: '.2em .4em',
                                        color: '#888',
                                        border: '1px solid #AAA',
                                        borderRadius: '2px',
                                        position: 'relative',
                                        top: '-2px',
                                   }}
                                   bg="outline-dark"
                              >
                                   {getBadge(idx)}
                              </Badge>
                         </Link>
                         <h6>
                              {getPosition(mode)}
                              &nbsp;&middot;&nbsp; {dontShowNewYork ? city : 'New York, NY'}
                         </h6>
                         <Stack direction="horizontal" gap={2} style={{ marginTop: 4 }}>
                              <Badge
                                   pill
                                   bg={getMatchBadgeColor(pctMatch)}
                                   style={{ marginBottom: 4 }}
                              >
                                   {pctMatch}% Match
                              </Badge>
                              <GetCheckmarks
                                   {...{
                                        num: pctMatch,
                                        dontShowNewYork,
                                        mode,
                                        idx,
                                        expansionsAndRefinements: expansionsAndRefinements || [],
                                   }}
                              />
                         </Stack>
                    </Heading>
               </div>
          </>
     );
};
const GREEN = '#20D489';
const GRAY = '#A1A5B7';

const GetCheckmarks = ({
     num,
     dontShowNewYork,
     mode,
     idx,
     expansionsAndRefinements,
}: {
     num: number;
     dontShowNewYork: boolean;
     mode: ResultsMode;
     idx: number;
     expansionsAndRefinements: ExpansionsAndRefinements[];
}) => {
     const variant = getMatchBadgeColor(num);
     let colors: [string, string, string, string] = [GREEN, GREEN, GREEN, GREEN];
     if (variant === 'warning') {
          colors = [GREEN, GRAY, GREEN, GREEN];
     } else if (variant === 'danger') {
          colors = [GREEN, GRAY, GRAY, GREEN];
     }
     if (dontShowNewYork) {
          colors[0] = GRAY;
     }

     let overrideFlags = ResultsModeToFlagsArray[mode];
     if (expansionsAndRefinements.length) {
          overrideFlags = ExpansionRefinementToFlagsArray[expansionsAndRefinements[0]];
     }
     if (overrideFlags && overrideFlags.length > 0 && overrideFlags[idx]?.length) {
          const thisFlagset = overrideFlags[idx];
          // console.log(`flags for ${idx} are ${thisFlagset}`);
          if (thisFlagset) {
               colors = [
                    thisFlagset[1] ? GREEN : GRAY,
                    thisFlagset[2] ? GREEN : GRAY,
                    thisFlagset[3] ? GREEN : GRAY,
                    thisFlagset[4] ? GREEN : GRAY,
               ];
          }
     }

     let locationString = 'New York, NY';
     if (colors[0] !== GREEN) {
          let otherLocations = [
               'Washington, DC',
               'San Francisco, CA',
               'Boston, MA',
               'Chicago, IL',
               'Los Angeles, CA',
          ];
          if (expansionsAndRefinements.includes(ExpansionsAndRefinements.SearchRemoteGlobally)) {
               otherLocations = [
                    'London, UK',
                    'Paris, France',
                    'Berlin, Germany',
                    'Amsterdam, Netherlands',
                    'Mexico City, Mexico',
               ];
          }
          locationString = otherLocations[idx % otherLocations.length];
     }

     let experience = 'Meets Experience Criteria';
     if (colors[3] !== GREEN) {
          experience = 'Does Not Meet Experience Criteria (2 years)';
     }
     if (mode === ResultsMode.CTO_FINTECH) {
          experience = 'Experience in Fintech';
     } else if (
          mode === ResultsMode.CTO &&
          expansionsAndRefinements.includes(ExpansionsAndRefinements.HasHealthcareExperience)
     ) {
          experience = 'Experience in Healthtech';
     }
     if (
          mode === ResultsMode.COO &&
          expansionsAndRefinements.includes(ExpansionsAndRefinements.AddCooWithExperienceInFintech)
     ) {
          experience = 'Experience in Fintech';
     }

     let withinBudget = 'Within Budget';
     if (colors[1] !== GREEN) {
          withinBudget = 'Not Within Budget';
          if (mode === ResultsMode.WEB_DESIGNER) {
               withinBudget = 'Not Within Budget ($100/hr)';
          }
          if (mode === ResultsMode.CHEAP_CFO) {
               withinBudget = 'Not Within Budget ($200/hr)';
          }
     }
     if (mode === ResultsMode.WEB_DESIGNER_TOMORROW && colors[1] !== GREEN) {
          withinBudget = 'Not Within Budget ($200/hr)';
     }
     if (mode === ResultsMode.COO && colors[1] !== GREEN) {
          withinBudget = 'Not Within Budget ($400/hr)';
     }

     let availability = 'Good Availability';
     if (colors[2] !== GREEN) {
          availability = 'Available next month';
     }

     return (
          <>
               <span style={{ fontWeight: 'normal', fontSize: 10 }}>
                    &nbsp;&nbsp;
                    {CheckCircle({ color: colors[0] })}
                    {locationString} &nbsp;
                    {CheckCircle({ color: colors[1] })}
                    {withinBudget} &nbsp;
                    {CheckCircle({ color: colors[2] })}
                    {availability} &nbsp;
                    {CheckCircle({ color: colors[3] })}
                    {experience} &nbsp;
               </span>
          </>
     );
};

function CheckCircle({ color }: { color: string }) {
     return (
          <i
               className={`bi ${color === GREEN ? 'bi-check-circle-fill' : 'bi-x-circle-fill'}`}
               style={{ color }}
          ></i>
     );
}

const getMatchBadgeColor = (num: number): Variant => {
     if (num > 85) {
          return 'primary';
     }
     if (num > 75) {
          return 'warning';
     }
     return 'danger';
};

function getPosition(mode: ResultsMode) {
     switch (+mode) {
          case ResultsMode.WEB_DESIGNER:
          case ResultsMode.WEB_DESIGNER_TOMORROW:
               return 'Web Designer';
          case ResultsMode.COO:
               return 'Chief Operating Officer';
          case ResultsMode.CHEAP_CFO:
               return 'Chief Financial Officer';
          case ResultsMode.CTO_FINTECH:
          case ResultsMode.CTO:
               return 'Chief Technology Officer';
          case ResultsMode.PYTHON_DEVELOPER:
               return 'Python Developer';
     }
}

function getBadge(idx: number) {
     const badges = ['Antlr Partner', 'Greyluck Partner', 'YCombinator Partner'];
     return badges[idx];
}

export function ProfileDropdown({ openChat, name }: { openChat: () => any; name: string }) {
     const [showVideo, setShowVideo] = useState(false);
     const [showAudio, setShowAudio] = useState(false);
     const [showAppointment, setShowAppointment] = useState(false);
     return (
          <>
               <Dropdown as={ButtonGroup} style={{ backgroundColor: '#F5F8FA' }}>
                    <Button variant="outline-dark" onClick={openChat}>
                         <i className="bi bi-chat-left-quote"></i>
                         &nbsp;Send Message
                    </Button>

                    <Dropdown.Toggle split variant="outline-dark" id="dropdown-split-basic" />

                    <Dropdown.Menu>
                         <Dropdown.Item href="#" onClick={() => setShowAppointment(true)}>
                              <i className="bi bi-calendar-plus"></i>&nbsp; Schedule an Appointment
                         </Dropdown.Item>
                         <Dropdown.Item href="#" onClick={() => setShowVideo(true)}>
                              <i className="bi bi-camera-video"></i>&nbsp;Video Chat
                         </Dropdown.Item>
                         <Dropdown.Item href="#" onClick={() => setShowAudio(true)}>
                              <i className="bi bi-telephone"></i>&nbsp;Audio Chat
                         </Dropdown.Item>
                    </Dropdown.Menu>
               </Dropdown>
               {showVideo && (
                    <VideoWindow
                         name={name}
                         close={() => {
                              setShowVideo(false);
                         }}
                    />
               )}
               {showAudio && (
                    <AudioWindow
                         name={name}
                         close={() => {
                              setShowAudio(false);
                         }}
                    />
               )}
               {showAppointment && (
                    <Appointment
                         name={name}
                         close={() => {
                              setShowAppointment(false);
                         }}
                    />
               )}
          </>
     );
}
