import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { FullScreenModal } from '../FullScreenModal';
import { ToggleButton } from 'react-bootstrap';
import { ReactNode, useState } from 'react';

export const Appointment = ({ close, name }: { close: () => any; name: string }) => (
     <FullScreenModal
          {...{
               close,
               closeButtonLabel: 'Schedule',
               title: `Schedule Appointment with ${name}`,
               width: '700px',
               height: '1000px',
          }}
     >
          <div
               className="modal-body"
               style={{
                    backgroundColor: 'white',
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 200,
               }}
          >
               <h2>Enter your availability</h2>
               <p>
                    When you time is finalzied, you will receive an invite with a unique link for
                    this video chat.
               </p>
               <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                         <DateCalendar />
                    </LocalizationProvider>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                         <h3>
                              Times are in&nbsp;&nbsp;
                              <a href="#" style={{ fontSize: 16, color: 'blue' }}>
                                   EST <span style={{ fontSize: 10 }}>▼</span>
                              </a>
                         </h3>
                         <Togs>9:00am</Togs>
                         <Togs>9:30am</Togs>
                         <Togs>10:00am</Togs>
                         <Togs>10:30am</Togs>
                         <Togs>11:00am</Togs>
                         <Togs>11:30am</Togs>
                         <Togs>12:00pm</Togs>
                         <Togs>1:00pm</Togs>
                    </div>
               </div>
          </div>
     </FullScreenModal>
);

export const Togs = ({ children }: { children: string }) => {
     const [checked, setChecked] = useState(false);
     return (
          <ToggleButton
               id={`toggle-check-${children.replace(/[^a-zA-Z0-9]/g, '')}`}
               type="checkbox"
               //    className="btn-outline-primary"
               value="1"
               variant="outline-primary"
               checked={checked}
               onChange={(e) => setChecked(e.currentTarget.checked)}
               style={
                    checked
                         ? { border: '1px solid #777' }
                         : { background: 'white', border: '1px solid black', color: 'black' }
               }
          >
               {children}
          </ToggleButton>
     );
};
