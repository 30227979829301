import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { VideoWindow } from './Video';
import { Appointment } from './Appointment';
import { AudioWindow } from './Audio';

type ChatMessage = {
     msg: string;
     you: boolean;
};

export const Chat1 = ({
     initialText = [{ msg: '(This is the start of your conversation)', you: false }],
     title = 'Candace Freeman',
     close,
}: {
     initialText?: ChatMessage[];
     title?: string;
     close: () => any;
}) => {
     const inputRef = useRef<HTMLTextAreaElement | null>(null);
     const scrollRef = useRef<HTMLDivElement>(null);
     const [chatHistory, setChatHistory] = useState<ChatMessage[]>(initialText);
     const [showVideo, setShowVideo] = useState(false);
     const [showAudio, setShowAudio] = useState(false);
     const [showAppointment, setShowAppointment] = useState(false);
     const scrollDown = () => {
          scrollRef.current && (scrollRef.current.scrollTop = 1000000);
     };
     const onEnter = () => {
          const newChat = [...chatHistory];
          if (inputRef.current?.value) {
               newChat.push({ msg: inputRef.current?.value, you: true });
          }
          setChatHistory(newChat);
          inputRef.current && (inputRef.current.value = '');
          requestAnimationFrame(() => {
               scrollDown();
               if (newChat.length < 3)
                    setTimeout(() => {
                         setChatHistory([
                              ...newChat,
                              {
                                   msg: 'Thanks for reaching out.  When are you free to chat?',
                                   you: false,
                              },
                         ]);
                         scrollDown();
                    }, 1500);
          });
     };
     useEffect(scrollDown, []);

     return (
          <>
               {showVideo && (
                    <VideoWindow
                         name={title}
                         close={() => {
                              setShowVideo(false);
                         }}
                    />
               )}
               {showAudio && (
                    <AudioWindow
                         name={title}
                         close={() => {
                              setShowAudio(false);
                         }}
                    />
               )}
               {showAppointment && (
                    <Appointment
                         name={title}
                         close={() => {
                              setShowAppointment(false);
                         }}
                    />
               )}
               <div
                    className="toast show"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    style={{
                         position: 'fixed',
                         bottom: 0,
                         right: 0,
                         margin: 10,
                         zIndex: 2,
                    }}
               >
                    <div className="toast-header">
                         <strong className="me-auto">{title}</strong>
                         <small className="text-body-secondary" style={{ marginRight: 20 }}>
                              {chatHistory.length ? 2 : 20} mins ago
                         </small>

                         <button
                              type="button"
                              data-bs-dismiss="toast"
                              aria-label="Appointment"
                              style={{ marginRight: 10 }}
                              onClick={() => setShowAppointment(true)}
                         >
                              <i className="bi bi-calendar-plus"></i>
                         </button>
                         <button
                              type="button"
                              data-bs-dismiss="toast"
                              aria-label="Video"
                              style={{ marginRight: 10 }}
                              onClick={() => setShowVideo(true)}
                         >
                              <i className="bi bi-camera-video"></i>
                         </button>
                         <button
                              type="button"
                              data-bs-dismiss="toast"
                              aria-label="Phone"
                              onClick={() => setShowAudio(true)}
                         >
                              <i className="bi bi-telephone"></i>
                         </button>
                         <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="toast"
                              aria-label="Close"
                              onClick={close}
                         ></button>
                    </div>
                    <div
                         className="toast-body"
                         style={{ height: 300, background: 'white', overflowY: 'scroll' }}
                         ref={scrollRef}
                    >
                         {chatHistory.map((h, i) => {
                              const left = !h.you;
                              return (
                                   <>
                                        <p
                                             style={{
                                                  textAlign: left ? 'left' : 'right',
                                                  marginLeft: left ? 0 : 40,
                                                  marginRight: left ? 40 : 0,
                                             }}
                                        >
                                             <div
                                                  style={{
                                                       display: 'inline-block',
                                                       border: `1px solid ${left ? 'gray' : '#444'}`,
                                                       backgroundColor: `${left ? 'gray' : 'white'}`,
                                                       color: `${left ? 'white' : '#444'}`,
                                                       borderRadius: 15,
                                                       padding: 10,
                                                  }}
                                             >
                                                  {h.msg}
                                             </div>
                                        </p>
                                        {!h.you && h.msg.includes('free') && (
                                             <div
                                                  className="symbol-label bg-light-info"
                                                  style={{
                                                       padding: 5,
                                                       marginTop: 5,
                                                       marginBottom: 5,
                                                       cursor: 'pointer',
                                                       textAlign: 'center',
                                                  }}
                                                  onClick={() => setShowAppointment(true)}
                                             >
                                                  <i className="bi bi-calendar-plus"></i>&nbsp;
                                                  &nbsp;&nbsp;Send scheduling details
                                             </div>
                                        )}
                                   </>
                              );
                         })}
                    </div>
                    <InputGroup>
                         <Form.Control
                              ref={inputRef}
                              size="sm"
                              autoFocus
                              type="text"
                              as="textarea"
                              defaultValue=""
                              placeholder="Aa"
                              onKeyPress={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
                                   if (event.key === 'Enter') {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        onEnter();
                                   }
                              }}
                              //  onChange={(event) => submitChat(event.target.value)}
                         ></Form.Control>
                         <Button onClick={onEnter}>Enter</Button>
                    </InputGroup>
               </div>
          </>
     );
};
