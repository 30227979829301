import { ListGroup } from 'react-bootstrap';
import { generateFakeFractionalProfiles } from '../mockData/GenerateFractionalProfile';
import { ProfileCard } from './ProfileCard';
import { FractionalProfileType } from '../onboarding/FractionalProfileType';
import { useEffect, useRef, useState } from 'react';
import { ExpansionsAndRefinements, ResultsMode } from './ConfigTypes';
// import { usePrevious } from '@uidotdev/usehooks';
function usePrevious<T>(value: T) {
     const ref = useRef<T>();
     useEffect(() => {
          ref.current = value;
     });
     return ref.current;
}

export const SomeResults = ({
     num,
     mode,
     expansionsAndRefinements,
}: {
     num: number;
     mode: ResultsMode;
     expansionsAndRefinements?: ExpansionsAndRefinements[];
}) => {
     const [profiles, setProfiles] = useState<FractionalProfileType[]>([]);
     const previousMode = usePrevious(mode);

     useEffect(() => {
          const previousNum = profiles.length;
          if (mode !== previousMode) {
               console.log(`changing mode from ${previousMode} to ${mode}`);
               setProfiles(generateFakeFractionalProfiles(num));
               return;
          }
          if (mode !== previousMode || (previousNum == 0 && num !== 0)) {
               setProfiles(generateFakeFractionalProfiles(num));
               return;
          }
          if (previousNum > num) {
               console.log(`removing ${previousNum - num} profiles`);
               setProfiles(profiles.slice(0, num));
               return;
          } else if (previousNum < num) {
               console.log(`adding ${num - previousNum} profiles`);
               setProfiles([...profiles, ...generateFakeFractionalProfiles(num - previousNum)]);
               return;
          } else {
               setProfiles(generateFakeFractionalProfiles(num));
               return;
          }
     }, [num, mode, expansionsAndRefinements]);

     return (
          <ListGroup>
               {profiles.map((profile, idx) => (
                    <ListGroup.Item>
                         <ProfileCard
                              mode={mode}
                              profile={profile}
                              idx={idx}
                              expansionsAndRefinements={expansionsAndRefinements}
                         />
                    </ListGroup.Item>
               ))}
          </ListGroup>
     );
};
