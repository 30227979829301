import { useState } from 'react';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { ExpansionAndRefinementConfig, ResultsModeToExpansionsOrRefinements } from './Config';
import { ExpansionsAndRefinements, ResultsMode } from './ConfigTypes';

type Props = {
     close: () => void;
     // override: (bool: boolean) => void;
     startDate: boolean;
     setStartDate: React.Dispatch<React.SetStateAction<boolean>>;
     remote: boolean;
     setRemote: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ExpandAndRefineResults = ({
     resultsMode,
     currentExpansionAndRefinements,
     setExpansionRefinement,
}: {
     resultsMode: ResultsMode;
     currentExpansionAndRefinements: ExpansionsAndRefinements[];
     setExpansionRefinement: (which: ExpansionsAndRefinements, val: boolean) => void;
}) => {
     const expansionsRefinements = ResultsModeToExpansionsOrRefinements[resultsMode];
     if (expansionsRefinements.length === 0) {
          return null;
     }
     const expandOrRefineWord =
          expansionsRefinements.length &&
          ExpansionAndRefinementConfig[expansionsRefinements[0]].type === 'EXPANSION'
               ? 'expand'
               : 'refine';
     return (
          <>
               <h3 style={{ marginTop: 20 }}>Suggestions to {expandOrRefineWord} your search...</h3>
               <div className="alert alert-secondary" role="alert">
                    {expansionsRefinements.map((x) => {
                         const isYes = currentExpansionAndRefinements.includes(x);
                         return (
                              <Form key={`${x}_${isYes}`}>
                                   <Form.Label>{ExpansionAndRefinementConfig[x].label}</Form.Label>
                                   <ButtonGroup>
                                        <Button
                                             variant={isYes ? 'dark' : 'outline-dark'}
                                             onClick={() => {
                                                  setExpansionRefinement(x, true);
                                             }}
                                        >
                                             Yes
                                        </Button>
                                        <Button
                                             variant={!isYes ? 'dark' : 'outline-dark'}
                                             onClick={() => {
                                                  setExpansionRefinement(x, false);
                                             }}
                                        >
                                             No
                                        </Button>
                                   </ButtonGroup>
                              </Form>
                         );
                    })}
               </div>
          </>
     );
};

// export const ExpandResultsStartDate = ({
//      close,
//      // override,
//      startDate,
//      setStartDate,
//      remote,
//      setRemote,
// }: Props) => {
//      return (
//           <div className="alert alert-secondary" role="alert">
//                <Form>
//                     <Form.Label>Flexible on start date?&nbsp;</Form.Label>
//                     <ButtonGroup>
//                          <Button
//                               variant={startDate ? 'dark' : 'outline-dark'}
//                               onClick={() => {
//                                    setStartDate(true);
//                                    // override(true);
//                               }}
//                          >
//                               Yes
//                          </Button>
//                          <Button
//                               variant={startDate ? 'outline-dark' : 'dark'}
//                               onClick={() => {
//                                    setStartDate(false);
//                                    // override(false);
//                               }}
//                          >
//                               No
//                          </Button>
//                     </ButtonGroup>
//                </Form>
//                <br />
//                <Form>
//                     <Form.Label>Willing to consider remote candidates?&nbsp;</Form.Label>
//                     <ButtonGroup>
//                          <Button
//                               variant={remote ? 'dark' : 'outline-dark'}
//                               onClick={() => {
//                                    setRemote(true);
//                                    // override(true);
//                               }}
//                          >
//                               Yes
//                          </Button>
//                          <Button
//                               variant={remote ? 'outline-dark' : 'dark'}
//                               onClick={() => {
//                                    setRemote(false);
//                                    // override(false);
//                               }}
//                          >
//                               No
//                          </Button>
//                     </ButtonGroup>
//                </Form>
//           </div>
//      );
// };

type Props2 = {
     close: () => void;
     // override: (bool: boolean) => void;
     cost: boolean;
     setCost: React.Dispatch<React.SetStateAction<boolean>>;
     hours: boolean;
     setHours: React.Dispatch<React.SetStateAction<boolean>>;
};
// export const ExpandResultsCheap = ({ close, cost, setCost, hours, setHours }: Props2) => {
//      return (
//           <div className="alert alert-secondary" role="alert">
//                {/* <a className="icon-link" href="#" style={{ float: 'right' }} onClick={() => close()}>
//                     <svg
//                          xmlns="http://www.w3.org/2000/svg"
//                          width="16"
//                          height="16"
//                          fill="currentColor"
//                          className="bi bi-x-lg"
//                          viewBox="0 0 16 16"
//                     >
//                          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
//                     </svg>
//                </a> */}
//                <Form>
//                     <Form.Label>
//                          Search for candidates outside your suggested rate?&nbsp;
//                     </Form.Label>
//                     <ButtonGroup>
//                          <Button
//                               variant={cost ? 'dark' : 'outline-dark'}
//                               onClick={() => {
//                                    setCost(true);
//                                    // override(true);
//                               }}
//                          >
//                               Yes
//                          </Button>
//                          <Button
//                               variant={cost ? 'outline-dark' : 'dark'}
//                               onClick={() => {
//                                    setCost(false);
//                                    // override(false);
//                               }}
//                          >
//                               No
//                          </Button>
//                     </ButtonGroup>
//                </Form>
//                <br />
//                <Form>
//                     <Form.Label>Willing to hire fractional CFOs?&nbsp;</Form.Label>
//                     <ButtonGroup>
//                          <Button
//                               variant={hours ? 'dark' : 'outline-dark'}
//                               onClick={() => {
//                                    setHours(true);
//                                    // override(true);
//                               }}
//                          >
//                               Yes
//                          </Button>
//                          <Button
//                               variant={hours ? 'outline-dark' : 'dark'}
//                               onClick={() => {
//                                    setHours(false);
//                                    // override(false);
//                               }}
//                          >
//                               No
//                          </Button>
//                     </ButtonGroup>
//                </Form>
//           </div>
//      );
// };
