import { FullScreenModal } from '../FullScreenModal';
import phonecall from './phonecall.png';
export const AudioWindow = ({ close, name }: { close: () => any; name: string }) => (
     <FullScreenModal {...{ close, title: `Phonecall` }}>
          <div
               className="modal-body"
               style={{
                    backgroundColor: 'white',
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 200,
                    flexDirection: 'column',
               }}
          >
               <h1>{name}</h1>
               <img src={phonecall} />
          </div>
     </FullScreenModal>
);
