import { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';

export const Loading = ({ doneLoading }: { doneLoading: () => void }) => {
     const [count, setCount] = useState<number>(0);

     useEffect(() => {
          setCount(44);
          setTimeout(() => {
               setCount(68);
               setTimeout(() => {
                    setCount(100);
                    requestAnimationFrame(() => doneLoading());
               }, 400);
          }, 400);
     }, []);

     return (
          <>
               Recommending candidates...
               <ProgressBar animated now={count} />
          </>
     );
};
