import { Collapse, Fade } from 'react-bootstrap';
import { Mode } from '../App2';

export function ShowFor({
     showFor,
     current,
     children,
}: {
     showFor: string | string[];
     current: string;
     children: React.ReactElement;
}) {
     const shouldShow = showFor instanceof Array ? showFor.includes(current) : showFor === current;
     return (
          <Fade in={shouldShow}>
               <Collapse in={shouldShow}>
                    <div style={{ clear: 'both' }}>{children}</div>
               </Collapse>
          </Fade>
     );
}
