import { useState } from 'react';
import { FullScreenModal } from '../FullScreenModal';
import { Chat1 } from '../chat/Chat1';
import { FractionalProfileType } from '../onboarding/FractionalProfileType';
import { ProfileDropdown } from './ProfileCard';

export const FullProfile = ({
     profile,
     close,
}: {
     profile: FractionalProfileType;
     close: () => any;
}) => {
     const [chat, setChat] = useState(false);

     const { companyName, operatorOrCeoName, email, phone, city, state, zip, profilePhoto } =
          profile;
     return (
          <>
               {' '}
               {chat && <Chat1 title={operatorOrCeoName} close={() => setChat(false)} />}
               <FullScreenModal
                    backgroundColor="white"
                    {...{ close, title: profile.operatorOrCeoName }}
               >
                    <div className="modal-body">
                         <div>
                              <span style={{ float: 'right' }}>
                                   <ProfileDropdown
                                        openChat={() => setChat(true)}
                                        name={operatorOrCeoName}
                                   />
                              </span>
                         </div>

                         <div style={{ textAlign: 'center', clear: 'both' }}>
                              <div style={{ textAlign: 'center', borderRadius: '100%' }}>
                                   <img
                                        src={profilePhoto}
                                        style={{
                                             textAlign: 'center',
                                             borderRadius: '100%',
                                             display: 'inline-block',
                                        }}
                                   />
                              </div>
                              <div>
                                   <a href="#">{email}</a>
                              </div>
                              <div>{phone}</div>
                              <div>New York, NY</div>
                              <br />
                              <br />
                              <div>
                                   <a href="#">Scheduling</a> | <a href="#">X/Twitter</a> |{' '}
                                   <a href="#">Instagram</a>
                              </div>
                         </div>
                         <br />
                         <div>
                              <b>Experience</b>
                              <CompanyCard
                                   title="April 2023-Present: VP of Design, QuillBot"
                                   body="Leading design and research for Quillbot, an AI writing company within the Learneo platform."
                              />
                              <CompanyCard
                                   title="Oct 2022 - Apr 2023: Principal at CW Design"
                                   body="Design and consulting services, focusing on research, strategy, team and process design."
                              />
                              <CompanyCard
                                   title="June 2017-Dec 2021: Head of Design at ZDo"
                                   body="Grew the team from six to 20+ people, adding in the disciplines of UX Research, Design Ops, and UX writing. I helped transition to a new business model and come out of the pandemic stronger than we went into it. I take pride in having built a team where people stayed a long time and grew from ICs into design leaders who could contribute to everything from strategy and roadmap planning, to nuanced design experiments and decisions."
                              />
                              <CompanyCard
                                   title="Oct 2015-June 2017: Principal @ Ludic Studio"
                                   body="Started the Portland-based design and innovation consultancy to help teams create and refine products and services that provide real value and meaning to their customers. I’m building a studio that takes the best of classical human-centered design methods and combines them with the new tools at our disposal to create great work, products, and relationships. Clients include LifeBEAM and Sherpa."
                              />
                              <CompanyCard
                                   title="Dec 2015-Apr 2016: Director of Interaction Design @ MATTR"
                                   body="Served as the digital design lead for a large scale hospitality/travel project that crossed multiple disciplines: digital, physical, and service design. I collaborated with the project’s creative director and studio leadership to shape the approach and grow the team to a scale appropriate for the scope of the program. I worked on a contract basis, commuting from Portland to San Francisco as needed, and rolled off as the team grew to accommodate the scale and pace of the program."
                              />
                              <CompanyCard
                                   title="Sep 2012-Oct 2015: Interaction Designer @ Smrt D Sign"
                                   body="Hired and developed the team to evolve the design and research approach to use more artifact-based, lean methods. I was heavily involved in business development, design research, and innovation strategy. During that time team has worked on mobile apps, web applications, smart home devices, connected athletic devices, and conducted international research.
                         In addition to running the IxD team, I led cross-disciplinary teams in programs that incorporated a range of skills including design research, service design, industrial design, and communication design.
                         "
                              />
                              <CompanyCard
                                   title="Jan 2010-Sep 2015: Adjunct Professor @ SVA"
                                   body="I co-taught a course in Prototyping User Experiences (now Crafting Interactions) since the MFA program’s inception in 2010. Our topics evolved each year, but have covered concept mapping, journey mapping, paper prototyping, user testing, digital prototyping, physical prototyping, and video prototyping. The goal, however, was consistent: give the students tools think carefully about design problems but also be bold in crafting experiments, testing, and incorporating the feedback to evolve the designs."
                              />
                         </div>
                         <div>
                              <b>Education</b>
                              <CompanyCard
                                   icon="bi-book"
                                   title="University of Miami, MA in Cultural Studies"
                                   body="1994-1996"
                              />
                              <CompanyCard
                                   icon="bi-book"
                                   title="UVA, BA in Design"
                                   body="1989-1993"
                              />
                         </div>
                    </div>
               </FullScreenModal>
          </>
     );
};

export const CompanyCard = ({
     title,
     body,
     icon,
}: {
     title: string;
     body: string;
     icon?: string;
}) => (
     <div className="card">
          <div className="card-body" style={{ padding: 0 }}>
               <h5 className="card-title">
                    {icon ? (
                         <i className={`bi ${icon}`} style={{ color: '#F1416C' }}>
                              &nbsp;&nbsp;
                         </i>
                    ) : (
                         <svg
                              style={{ display: 'inline' }}
                              width="20"
                              height="20"
                              viewBox="0 0 200 200"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M64.6601 175.57V67.57L74.9401 55.57V171.35C81.193 167.743 86.3855 162.553 89.9948 156.302C93.6042 150.05 95.503 142.959 95.5001 135.74V0L44.1001 60V176.9H54.3801C57.8492 176.893 61.3035 176.446 64.6601 175.57V175.57Z"
                                   fill="#37A56E"
                              />
                              <path
                                   d="M157.19 187.15V98.53L105.78 38.53V135.72C105.767 149.349 100.347 162.416 90.7113 172.054C81.0751 181.692 68.009 187.114 54.38 187.13H21V200H126.35V94.14L136.63 106.14V200H179V187.15H157.19Z"
                                   fill="#3B3D3C"
                              />
                         </svg>
                    )}
                    {/*  */}
                    {title}
               </h5>
               <p className="card-text">{body}</p>
          </div>
     </div>
);
